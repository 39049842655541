.registration-phone-number {
  .PhoneInput {
    @media (min-width: 600px) {
      margin-top: 28px;
    }

    .PhoneInputCountry {
      height: 53px;
      width: 75px;
      justify-content: space-between;
      padding: 12px;
      border-radius: 4px;

      &:hover {
        background-color: rgba(0, 0, 0, 0.08);
      }
    }

    .PhoneInputInput {
      height: 53px;
      border-radius: 4px;

      &:hover {
        outline: -webkit-focus-ring-color auto 1px;
      }
    }

    #phone-number-error {
      border-color: #CD0202;
      border-width: 2px;
    }

    .PhoneInputCountrySelectArrow {
      height: 8px;
      width: 8px;
      color: #000000 !important;
    }

    .PhoneInputCountryIcon {
      box-shadow: 0 0 0 !important;
    }
  }

  .PhoneInput--focus {
    .PhoneInputInput {
      outline-color: #0071C7 !important;
    }
  }
}
